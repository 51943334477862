<template >
  <div v-show="show">
    <block-title>{{ title }}</block-title>
    <div v-show="stage == 1" class="mb-8">
      <v-checkbox v-model="selectAll" label="全選" hide-details></v-checkbox>
      <div v-for="(item, index) in data" :key="index">
        <v-checkbox
          v-model="checkItem"
          :label="item.product"
          :value="item.item_id"
          hide-details
        ></v-checkbox>
      </div>
    </div>
    <div v-show="[2, 3].includes(stage)">
      <BackItems
        v-model="selectedData"
        :backReason="backReason"
        :propsPhotos.sync="photos"
        :propsComment.sync="comment"
        :isDisable="stage == 3"
        :propsFormKey="formKey"
      ></BackItems>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">

import orderConstants from "@/modules/base/config/orderConstants"
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import BackItems from './BackItems2.vue'

export default {
    mixins: [ItemMixin],
    components : {
        BackItems,
        
    },
    props : {
        props: Object,
    },
    data: () => ({
        propsValueInit: false,
        data : null,
        orderConstants : orderConstants,
        checkItem: [],
        selectedData: [],
        comment : null,
        photos : [],
        backReason : []
    }),
    async created() { 

    },
    watch : {
        checkItem: {
            deep: true,
            handler() {
                const selected = this.checkItem.map(item => {
                    const target = this.data.find(dataItem => dataItem.item_id == item)
                    return target
                })
                this.selectedData = this.$eagleLodash.cloneDeep(selected)
            }
        },
        photos : {
            immediate: true,
            deep: true,
            handler() {
                // this.props.setPhotos();
                // console.log('baS:',this.photos)
            },
        },
  
        propsValue : {
            immediate: true,
            deep: true,
            handler() {
                if(this.propsValueInit) return
                if(!this.propsValue) return ;
                this.data = this.$eagleLodash.cloneDeep(this.propsValue.items)
                this.backReason = this.$eagleLodash.cloneDeep(this.propsValue.backReason)
                this.propsValueInit = true
            },
        },
   
        async stage() {
            this.$vuetify.goTo(0);
        },
    },
    computed: {
        selectAll: {
            get() {
                if(!this.checkItem) return false
                if(!this.data) return false
                return this.checkItem.length == this.data.length
            },
            set(value) {
                this.selectAllFun(value)
            }
        },
        stage() {
            if(!this.props) return 1
            return this.props.stage
        },
        show() {
            if(!this.props) return false
            return this.props.show
        },
        items() {
            if(!this.propsValue) return []
            return this.propsValue.items
        },
        title() {
            if(this.stage == 1) return '請勾選退貨的品項'
            if(this.stage == 2) return '請填寫退貨品項的數量及原因'
            if(this.stage == 3) return '請再次確認退貨品項'
            return ''
        },
    },
    methods: {
        selectAllFun(all = false) {
            this.checkItem = []
            if(all) {
                this.data.forEach( (item,index)  => {
                    this.checkItem.push(item.item_id)
                })
            }
        },
    },
}
</script>

