<template >
    <v-card>
      <input :value="propItem.item_id"  :name="`items[${index}][item_id]`">
      <v-text-field v-model="product.count"  :name="`items[${index}][count]`"></v-text-field>
      <v-text-field :value="product.price" readonly></v-text-field>
      <v-select
        :items="backReason"
        label="退貨原因"
        class="ml-4 mb-3 opendaySelect"
        hide-details
        outlined
        width="200"
        :name="`items[${index}][reason]`" ></v-select>
      <v-text-field v-model="total"></v-text-field>
      
    </v-card>
</template>

<script lang="babel" type="text/babel">

export default {
  props : {
    propItem : [Array,Object],
    index : [String,Number],
    value : [Object,Array],
    backReason : Array
  },
  data: () => ({
    product : {
      count : 0,
      price : 0,
    },
    total : 0
  }),
  async created() { 
  },
  watch : {
    value : {
      immediate: true,
      handler() {
        this.product.count = +this.propItem.count
        this.product.price = +this.propItem.price
        this.setTotal ()
      }
    },
    product : {
      deep: true,
      handler() {
        this.$emit('update:propItem',{...this.propItem,...this.product});
        this.setTotal()
      },
    },

    total: {
      immediate: true,
      handler() {
        // 總total 傳上去
        this.$emit('changeTotal');
     
      }
    },
  },
  computed: {


  },
  methods: {
    setTotal () {
      this.total = this.product.price * this.product.count
    }
  },
}
</script>

