<template >
  <div>
    <template v-for="(item, index) in data">
      <storeApplyReturnOrderCard
        :key="`card-${item.item_id}-${isDisable}`"
        v-model="data[index]"
        :configs="cardConfigs(index)"
      ></storeApplyReturnOrderCard>

      <input
        :value="item.item_id"
        hidden
        :name="`items[${index}][item_id]`"
        :key="item.id"
      />
    </template>

    <block-title :hasBorder="false">圖片上傳</block-title>
    <photo-selector
      v-model="photos"
      :simpleMode="true"
      multiple
      :total="4"
      :names="names"
      :formKey="formKey"
      class="mb-5"
      :readonly="isDisable"
      :getHasPhoto.sync="hasPhoto"
    ></photo-selector>

    <v-textarea
      v-model="comment"
      :disabled="isDisable"
      name="comment"
      outlined
      class="mb-5"
      label="備註"
      :rules="rules"
      required
    ></v-textarea>

<!--    <number-bar-->
<!--      title="退款合計"-->
<!--      unit="元"-->
<!--      v-model="amount"-->
<!--      readonly-->
<!--      type="secondary"-->
<!--    ></number-bar>-->
  </div>
</template>

<script lang="babel" type="text/babel">

import ItemMixin from '@/components/form/custom/ItemMixin.js'
import amountTotal from "@/mixins/amountTotal.js"
import BackItem from './BackItem.vue'
import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue"
import storeApplyReturnOrderCard from "@/modules/base/components/priceCard/returnOrder/storeApplyReturnOrderCard.vue"
import countMixins from "@/mixins/count"

export default {
  mixins: [ItemMixin, amountTotal, countMixins],
  components: {
    storeApplyReturnOrderCard
  },
  props : {
    selectItem : Object,
    propsComment : String,
    propsPhotos: Array,
    backReason : Array,
    isDisable : {
        type : Boolean,
        default : false
    },
    value: Array
  },
  data: () => ({
    data : [],
    comment : null,
    photos : [],
    hasPhoto: false,
  }),
  mounted() {
    this.$emit('update:getHasPhoto',this.hasPhoto)
  },
  watch : {
    data: {
      deep: true,
      async handler() {
        if (this.$eagleLodash.isEqual(this.value,this.data)) return
        this.$emit('input',this.data);
        await this.getChildrenTotal()
      }
    },
    value: {
      immediate: true,
      deep: true,
      async handler() {
        if(!this.value) return
        this.data = this.$eagleLodash.cloneDeep(this.value).map(item => {
          return {
            ...item,
            reason: item.reason ? item.reason : this.backReason[0].value 
          }
        })

        // 一開始要等子元件渲染完
        await this.$helper.delay(0.5)
        await this.getChildrenTotal()
      }
    },
    propsPhotos : {
        immediate: true,
        handler() {
            this.photos = this.propsPhotos;
      },
    },

    photos : {
        handler() {
            this.$emit('update:propsPhotos',this.photos)
        },
    },

    propsComment : {
      immediate: true,
      handler() {
        this.comment = this.propsComment;
      },
    },
    comment : {
      handler() {
        this.$emit('update:propsComment',this.comment)
      },
    },

    hasPhoto: {
      immediate: true,
      handler() {
        this.$store.dispatch(`customForm/${this.formKey}/setOtherValidate`, {
          key: 'photo',
          value: {
            has: this.hasPhoto,
            message: '圖片必填, 至少一張',
          }
        })
      },
    },
  },
  computed: {
    names() {
      return this.photos.reduce((acc, cur, index) => {
        let name = `imgs[${index}]`
        acc.push(name)
        return acc
      }, [])
    },
    rules() {
      return [
        v => !!v || '備註必填',
      ]
    },
  },
  methods: {
    cardConfigs(index) {
      const item = this.data[index]
      // console.log('item', item)
      return [
        {label: item.product, colon: false},
        { id: "unit", col: 6 },
        {
          id: "price",
          name: `value[${index}][price]`,
          labelColor: "primary--text",
          valueSuffix: "元",
          col: 6
        }, 
        { 
          id: "return_count",
          label: "退貨數量",  
          type: 'number-input' ,
          max: item.shipping,
          name: `items[${index}][count]`,
          disabled: this.isDisable,
          fixed: 2,
          rule: (count, max) => this.countRule({count, unit: item.shipping_unit, max}),
        },
        {
          id: 'reason',
          type: 'selection' ,
          options: this.backReason,
          label: '退貨原因',
          name: `items[${index}][reason]`,
          disabled: this.isDisable
        },
        // {
        //   id: "total",
        //   label: "退款小計",
        //   type: "total",
        //   valueSuffix: "元",
        //   barType: 'gray'
        // },
      ]
    },
    async getChildrenTotal() {
      await this.$nextTick()
      this.amount = this.$children.reduce( (arr,cur) => {
        if(cur.$options.name != 'photoSelector') {
          if (cur.total) arr += Number(cur.total)
        }
        return Math.round(arr)
      },0);
    },
  },
}
</script>

