<template>
  <v-container className="pa-0">
    <price-card v-model="data" :configs="localConfigs"/>
  </v-container>
</template>

<script>
import cardMixins from "../mixins";
import discountMixins from "@/mixins/discount"

export default {
  mixins: [cardMixins, discountMixins],
  methods: {
    setPrice(configs) {
      const target = configs.find((config) => config.id === "price");
      if (!target) return;
      target.label = `單價`;
      target.value = this.value.price;
      target.valueSuffix = this.priceTextFunc({
        unit: this.calculate_unit,
        showPrice: false
      });
    },
    calculateTotal(target, config) {
      const {shipping, return_count, price, per_shipping_count, shipping_unit} = config;
      let total = this.count小計(config)
      target.value = isNaN(total) ? null : total;
      this.total = target.value
    },
    count小計(config) {
      const {shipping_unit, shipping, price, per_shipping_count, amount} = config
      // console.log('config',config)
      const return_count = this.$helper.transformShippingCount({count: this.value.return_count, unit: shipping_unit})
      // console.log('return_count', return_count)
      const quantity = (+shipping - return_count)
      // const price = this.getDiscountPrice({
      //   discount_config: config.discount_config,
      //   price: config.price,
      //   quantity
      // })
      // console.log('price', price, 'quantity',quantity)
      // console.log('小記', config.amount - ( quantity * price * per_shipping_count))
      return amount - ( quantity * price * per_shipping_count)
    },
    setReturnCount(configs) {
      const target = configs.find((config) => config.id === "return_count");
      if (!target) return;

      // target.value = this.value.shipping
      target.valueSuffix = this.shipping_unit
    },
  },
};
</script>